.card-style{
    display:flex;
    height:50vh;
    width:20vw;
    background-color:orange;
    align-items:center;
    justify-content:space-evenly;
    flex-direction:column;
}
img#card-img{
    width:95%;
    height:90%;
}
button{
    border:none;
}
span#card-text{
    font-weight:900;
    font-size:20px;
}
