.anime-body{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
}

.lel{
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    background-color:rgb(22, 233, 22);
    color: white;
    width:15vw;
    cursor: pointer;
    height:10vh;
    font-size:30px;
    font-weight:bolder;
    border-radius: 10px;
    position: relative;
    border:none;
}
.lel.animating{
    background-color: transparent;
    color:transparent;
    cursor:default;
    animation: 0ms ball 2000ms;
    animation-fill-mode: forwards;
}
.lel::before{
    content: "";
    display: none;
    background-color: rgb(22, 233, 22);
    position: absolute;
    font-size:30px;
    inset:0;
    border-radius: 0.5em;
    animation: 500ms fade cubic-bezier(0.27, 0.38, 0.16, 1.44);
    animation-fill-mode: forwards;
}
.lel.animating::before{
    display: block;
    
    
}
@keyframes fade {
    100%{   
        left:-40%;
        right:-40%;
        top:43%;
        bottom:43%;
        border-radius:0.25em;
    }
    
}
@keyframes progress {
    100%{left:-40%;right:-40%;}
}
.lel.animating::after{
    content:"";
    background-color:white;
    position: absolute;
    left:100%;
    right:100%;
    top:43%;
    bottom:43%;
    border-radius:0.25em;
    animation: 1.5s progress 400ms;
    animation-fill-mode: forwards;  
}
.svg-container{
    color:transparent;
    position: absolute;
}

.lel.animating + .svg-container{  
    width:0;
    height:0;
    color:transparent;
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius:0.5em;
    animation: 100ms circle 2000ms cubic-bezier(0.26, 0.6, 0.46, 1.7);
    animation-fill-mode: forwards;
}

@keyframes circle {
    100%{
        color: white;
        background-color:rgb(22, 233, 22); 
        height:100px;
        width:100px;
        bottom:5px;
        border-radius:100%;   
    }
}
@keyframes ball {
    100%{
        width:0;
        height:0;
        padding:0;
    }
}