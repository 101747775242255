.container-style{
    display: grid;
    justify-items: center;
}
hr{
    background-color:black;
    margin-top:10px;
}
h1{
    text-align: center;
}
.block{
    display: flex;
    block-size: 1px;
    border: 2px solid black !important;
    height: fit-content;
    width: auto;
    flex-direction: column;
    margin-top: 10px;
}
h5{
    text-align: left;
    align-items: center;
    padding-right: 150px;
    padding-top: 10px;
}
img{
    width: 150px;
    height: 200px;
    border: 2px solid black !important;
}
.hr {
        margin-top: 1px;
        margin-bottom: 1px;
        border: 1px solid black;
}
.order{
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-right: 0px;
}
.iblock{
    display: flex;
    flex-direction: column;
}
.ih5{
    padding-left: 10px;
}
h6{
    padding-left: 70px;
    margin-top: 0px;
}
first{
    justify-content: center;
    align-items: center;
}
.vr{
        border-left: 2px black;
        margin-top: 0px;
        border-style: solid;
        height: auto;
        margin-left: 0px;
}
.history-orders-container{
    display:flex;
    flex-direction: column;
    border: 2px solid black;
    width: 90vw;
    height: fit-content;
    padding: 10px;
    justify-content: flex-start;
    align-items: center;
}
.order-container{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    margin-top: 10px;
    width:95%;
    padding: 10px;
    border: 2px solid black;
    gap:10px;
    overflow-x: scroll !important;
}
.row-maker{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    width:100%;
    padding: 10px;
    gap:20px;
    overflow-x: scroll !important;
}
.order-item-container{
    display: flex;
    flex-direction: row;
    width:40%;
    height:20%;
    border: 2px solid black;
    justify-content: flex-start;
}
.order-item-desc{
    display: flex;
    flex-direction: column;
    gap:10px;
    width:20%;
    margin-left: 20px;
}