.screen-style{
    display: flex;
    align-items: center;
    height:100vh;
    flex-direction: column;
}

.kart-container{
    display:flex;
    flex-direction:column;
    width: 87%;
    height: 65vh;
    overflow-y: scroll;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    padding: 10px 10px 10px 10px;
    background-color:lightblue;
}
.kart-heading{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: 2px solid red;
}
.quantity-style{
    font-size: 25px;
}

.kart-item{
    display:flex;
    border:2px solid black;
    margin-top:10px;
    align-items:center;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height:40%;
    gap:20px;
    padding: 5px 5px 5px 5px;

}
.kart-columns{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}
.kart-headings{
    font-size:25px;
}
.kart-span-the-image{
    height:95%;
    border: 2px solid blue;
    margin-left: 10px;
    padding: 5px 5px 5px 5px;
}
.kart-book-cover-style{
    height:100%;
}
.kart-item-name{
    display:flex;
    width:10%;
    text-align:center;
    height:100%;
}

.control-bar-style{
    display: flex;
    align-items:center;
    gap: 10px;
    margin-left: 105px;
}

