.slider-container{
    margin-top: 10px;
}

.image-container{
    display: flex;
    width: 28vw;
    justify-content: center;
    cursor: pointer;
}
.image-style{
        width: 80%;
        height: 60vh;   
}
p#subs{
    font-size: 18px;
    font-weight: bolder;
}
.double-frame{
    display: flex;
    width:65vw;
    flex-direction: row;
    justify-content: center;

}