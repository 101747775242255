@keyframes color{
    10% {background-color: black;color:white}
    20% {background-color: orange;color:black}
    30% {background-color: black;color: orange;}
    40% {background-color: blue;color: white;}
    50% {background-color: gold;color: black;}
    60% {background-color: green;color: black;}
    70% {background-color: yellow;color: green;}
    80% {background-color: blue;color:yellow}
    90% {background-color: red;color: white;}
    100% {background-color: white;color:black}
    }
    .container-style{
        display: grid;
        justify-items: center;
    }
    .header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .heading-style{
        display: flex;
        font-size:40px;
        font-weight: bolder;
        font-style:italic;
        justify-content: center;
        width: 90vw;
        height:10vh;
        border-color: black;
        animation: 15s color infinite;
    }
    .nav-container{
        display: flex;
        justify-content: center;
        width:90vw;
        background-color:black;
        align-items: center;
        
    }
    
    .nav-style{
        display:flex;
        width:100%;
        gap: 10px;
        background-color:gold;
        border: 2px solid black !important;
        justify-content: center;
    }
    .navbtn-style{  
        margin-right: 20px;
        margin-left: 20px;
        font-weight: bold;
        color:black;
        font-size: 18px;
    }
    nav#navi button{
        display: flex;
        justify-content:center;
        align-items: center;
    }
    .search-style{
        display:flex;
        justify-content:center;
        flex-direction: column;
        width:90vw;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 10px;
    }

    .search-bar-style{
        display:flex;
        flex-direction: row;
        align-items:center;
        justify-content: center;
    }
    .results-style{
        display:flex;
        flex-direction: column;
        width: 37%;
        font-size: 18px;
        height: 30vh;
        overflow:scroll;
    }
    .hide-style{
        display:none;
    }
    .results-style{
        border: 1px solid black;
        cursor: pointer;

    }
    .btn:active{
        background-color: rgb(255, 187, 0);
    }