div.absolute {
    position: bottom;
    bottom: 0%;
    width: 100%;
    border: 3px solid black;
    height: fit-content;
    background-color: yellow;
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding-left: 5px;
    margin-top: 5px;
  }
  .first{
      padding-left: 20px;
  }
  .second{
      padding-left: 262px;
  }
  .third{
      margin-top: 0px;
      margin-bottom: 0px;
      border-top: 1px solid rgb(7, 7, 7);
  }
  .fourth{
      padding-left: 67px;
  }
  .fifth{
      padding-left: 100px;
  }
  .sixth{
      display: flex;
      flex-direction: row;
  }
  .seventh{
    border-left: 2px solid black;
    margin-top: 0px;
    margin-left: 30%;
  }
  .ninth{
      padding-left: 5px;
  }
  .tenth{
      padding-left: 77px;
  }