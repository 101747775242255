.categorylist-style{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.categorybook-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:80%;
    margin-left: 30px;
    border: 2px solid black;
    justify-content: flex-start;
    background-color:rgb(241,243,246,1);
    padding: 15px 15px 15px 15px;
}
.bookcontainer-style{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.category-heading {
    display:flex;
    font-size:20px;
    font-weight:bold;
}
.book-style{
    display:flex;
    flex-direction:column ;
    border: 2px solid black;
    padding: 5px 10px 5px 10px;
    justify-content: flex-start;
    align-items: center;
    width:35vw;
    height: 50vh;
    margin-top: 10px;
}
.span-the-image{
    height:85%;
    cursor: pointer;
    border: 2px solid blue;
    padding: 5px 5px 5px 5px;
}
.book-cover-style{
    height:100%;
}
.book-description{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
}
.content-holder{
    display:flex;
    flex-direction:row;
    height:90%;
    width:100%;
    justify-content:space-around;
}