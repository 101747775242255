@keyframes color{
20% {background-color: black;color:white}
40% {background-color: white;color:black}
60% {background-color: gold; color: black;}
80% {background-color: black;color:white}
100% {background-color: white;color:black}
}
.container-style{
    display: grid;
    justify-items: center;
}
hr{
    background-color:black;
    margin-top:10px;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.body{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:80%;
    border: 2px solid black;
    justify-content: flex-start;
    background-color:rgb(241,243,246,1);
    padding: 10px 10px 10px 10px;
}
.heading-style{
    display: flex;
    font-size:40px;
    font-weight: bolder;
    font-style:italic;
    justify-content: center;
    width: 90vw;
    height:10vh;
    border-color: black;
    animation: 10s color infinite;
}
input{
    width: 30vw;
    height:4.8vh;
}
.nav-container{
    display: flex;
    justify-content: center;
    width:90vw;
    background-color:black;
    align-items: center;
    
}

.nav-style{
    display:flex;
    width:100%;
    gap: 10px;
    background-color:gold;
    border: 2px solid black !important;
    justify-content: center;
}
.navbtn-style{  
    margin-right: 20px;
    margin-left: 20px;
    font-weight: bold;
    color:black;
    font-size: 18px;
}
nav#navi button{
    display: flex;
    justify-content:center;
    align-items: center;
}
.upper-body{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.slider-container{
    display: flex;
    justify-content: center;
    overflow: hidden;
    width:60vw;
    margin-bottom: 40px;
    flex-direction: row;
}
.banner-style{
    display: flex;
    font-family:sans-serif;
    font-size:30px;
    align-items:center;
    font-weight: bolder;
    touch-action: auto;
    justify-content:center;
}
.card-container{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    margin-top: 5vh;
    gap:20px;
}

#space{
    margin-top: 10px;
    align-items: center;
}
.spotlight-style{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    font-family: sans-serif;
    border: 2px solid black;
    padding: 10px 10px 10px 10px;
    background-color:rgb(152, 251, 152,0.5);
    
}

.series-name{
    font-size:30px;
    font-weight: bold;
    margin-left: 5vh;
    font-style: oblique;
    text-align:center;
}

.search-style{
    display:flex;
    justify-content:center;
    width:90vw;
    flex-wrap: wrap;
    align-items: center;
}